import Vue from 'vue'
import { create } from 'axios'
import router from '@/router'

const host = process.env.NODE_ENV === 'production' ? 'https://api.strong.expert' : 'http://localhost:3000'

const axios = create({
  baseURL: `${host}/api/v1`,
  headers: {
    authorization: `Bearer ${localStorage.getItem('token')}`,
  },
})

axios.interceptors.response.use(response => {
  const { data, status } = response
  const hasNotify = (!data && status === 200) || ([201, 204].indexOf(status) !== -1)

  if (hasNotify) {
    Vue.prototype.$notify(
      'Operação realizada com sucesso.',
      'success',
    )
  }

  return { data, status }
}, error => {
  const { response } = error

  if (!response) {
    Vue.prototype.$notify(
      'Erro ao procesar solicitação, tente novamente mais tarde.',
      'error',
    )
  }

  const { data, status } = response

  switch (status) {
    case 401:
      if (router.history.current.name !== 'signin') {
        localStorage.removeItem('fullname')
        localStorage.removeItem('token')
        localStorage.removeItem('tenant')
        localStorage.removeItem('isAffiliate')
        localStorage.removeItem('tokenApi')

        router.push({ path: '/signin' })
      }

      Vue.prototype.$notify(data.message, 'error')
      break

    case 403:
      Vue.prototype.$notify(data.message, 'warning')
      break

    default:
      Vue.prototype.$notify(data.message, 'error')
      break
  }

  return Promise.reject(error)
})

Vue.prototype.$axios = axios

export default axios
