import { unauthenticated } from './utils'

export default [
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/reports/smsTemplates/index.vue'),
    path: '/report/sms-template',
    name: 'reportSmsTemplate',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/reports/voice/index.vue'),
    path: '/report/voice',
    name: 'reportVoice',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/reports/services/v1/index.vue'),
    path: '/v1/report/service',
    name: 'v1ReportService',
  },
  {
    path: '/report/service',
    name: 'reportService',
    beforeEnter: unauthenticated,
    component: () => import('@/views/reports/services/index.vue'),
  },
]
