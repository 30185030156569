import Vue from 'vue'
import axios from '@/plugins/axios'

export const accountStatus = async () => {
  try {
    const { data } = await axios.get('confirmated-account')

    return data
  } catch (err) {
    return err
  }
}

export const sendSMS = async () => {
  try {
    const { data } = await axios.get('send-confirmation-code')

    return data
  } catch (err) {
    return err
  }
}

export const confirmAccount = async (_, code) => {
  try {
    const { data } = await axios.get(`confirm-account/${code}`)

    return data
  } catch (err) {
    return err
  }
}
