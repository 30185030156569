import Vue from 'vue'
import VueRouter from 'vue-router'

import account from './account'
import admin from './admin'
import affiliates from './affiliates'
import report from './report'
import service from './service'
import { authenticated, unauthenticated } from './utils'

const routes = [
  {
    path: '/signin',
    name: 'signin',
    beforeEnter: authenticated,
    component: () => import('@/views/auth/Signin.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/signup',
    name: 'signup',
    beforeEnter: authenticated,
    component: () => import('@/views/auth/Signup.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/recovery-password',
    name: 'recoveryPassword',
    beforeEnter: authenticated,
    component: () => import('@/views/auth/RecoveryPassword.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    beforeEnter: authenticated,
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/',
    beforeEnter: unauthenticated,
    component: () => import('@/views/account/walletExtract/index.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    beforeEnter: unauthenticated,
    component: () => import('@/views/dashboard/index.vue'),
  },
  ...account,
  ...admin,
  ...affiliates,
  ...service,
  ...report,
  {
    path: '/sms-templates',
    name: 'smsTemplates',
    beforeEnter: unauthenticated,
    component: () => import('@/views/smsTemplates/index.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    beforeEnter: unauthenticated,
    component: () => import('@/views/faq/index.vue'),
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    beforeEnter: unauthenticated,
    component: () => import('@/views/tutorials/index.vue'),
  },
  {
    path: '*',
    name: 'notFoundError',
    component: () => import('@/views/Error.vue'),
    meta: { layout: 'blank' },
  },
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
