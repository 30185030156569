<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    clipped
    width="230"
    color="dark"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <v-list
      class="vertical-nav-menu-items"
      expand
      shaped
    >
      <nav-menu-section-title title="MENU" />

      <!-- <nav-menu-link
        :icon="icons.mdiHomeOutline"
        :to="{ name: 'dashboard' }"
        title="Dashboard"
        dark
      /> -->

      <nav-menu-group
        :icon="icons.mdiFileChart"
        :title="'Relatórios'"
      >
        <nav-menu-link
          :to="{ name: 'reportService' }"
          title="Serviços"
        />
      </nav-menu-group>

      <nav-menu-link
        :icon="icons.mdiMessageDraw"
        :to="{ name: 'smsTemplates' }"
        title="SMS Templates"
      />

      <nav-menu-group
        v-if="isAdmin || isAffiliate"
        :icon="icons.mdiBadgeAccount"
        :title="'Afiliados'"
      >
        <nav-menu-link
          :to="{ name: 'affiliates' }"
          title="Relatórios"
        />
        <nav-menu-link
          v-if="isAdmin"
          :to="{ name: 'affiliatesComission' }"
          title="Comissão"
        />
      </nav-menu-group>

      <nav-menu-group
        v-if="isAdmin"
        :icon="icons.mdiHeadCog"
        :title="'Modereção'"
      >
        <nav-menu-link
          v-if="isAdmin"
          :to="{ name: 'reportVoice' }"
          title="Campanha Voz"
        />

        <nav-menu-link
          v-if="isAdmin"
          :to="{ name: 'reportSmsTemplate' }"
          title="SMS Template"
        />
      </nav-menu-group>

      <nav-menu-section-title title="SERVIÇOS" />

      <nav-menu-link
        :icon="icons.mdiMessageTextOutline"
        :to="{ name: 'serviceSms' }"
        title="SMS"
      />

      <nav-menu-link
        :icon="icons.mdiPhonePlusOutline"
        :to="{ name: 'serviceVoice' }"
        title="Torpedo Voz"
      />

      <nav-menu-link
        :icon="icons.mdiCheckAll"
        :to="{ name: 'serviceSanitizer' }"
        title="Higienizador"
      />

      <nav-menu-link
        :icon="icons.mdiCounter"
        :to="{ name: 'serviceNumberGenerator' }"
        title="Gerador de Números"
      />

      <nav-menu-section-title
        v-if="!isAdmin"
        title="CONTA"
      />

      <nav-menu-link
        v-if="!isAdmin"
        :icon="icons.mdiPiggyBankOutline"
        :to="{ name: 'accountHirePlan' }"
        title="Inserir Saldo"
      />

      <nav-menu-link
        v-if="!isAdmin"
        :icon="icons.mdiFileDocument"
        :to="{ name: 'accountWalletExtract' }"
        title="Extrato"
      />

      <nav-menu-section-title
        v-if="isAdmin"
        title="ADMIN"
      />

      <nav-menu-link
        v-if="isAdmin"
        :icon="icons.mdiAccountCircleOutline"
        :to="{ name: 'adminUser' }"
        title="Usuários"
      />

      <nav-menu-link
        v-if="isAdmin"
        :icon="icons.mdiPiggyBankOutline"
        :to="{ name: 'adminHirePlan' }"
        title="Inserir Saldo"
      />

      <nav-menu-link
        v-if="isAdmin"
        :icon="icons.mdiFileDocument"
        :to="{ name: 'adminWalletExtract' }"
        title="Extrato"
      />

      <nav-menu-link
        v-if="isAdmin"
        :icon="icons.mdiStairs"
        :to="{ name: 'adminLevels' }"
        title="Níveis"
      />

      <nav-menu-link
        v-if="isAdmin"
        :icon="icons.mdiCash"
        :to="{ name: 'adminTaxes' }"
        title="Tarifas"
      />

      <nav-menu-section-title title="Suporte" />

      <nav-menu-link
        :icon="icons.mdiVideo"
        :to="{ name: 'tutorial' }"
        title="Tutoriais"
      />

      <nav-menu-link
        :icon="icons.mdiApi"
        title="Documentação"
        href="https://strong-expert.apidocumentation.com"
        target="_blank"
      />

      <nav-menu-link
        :icon="icons.mdiWhatsapp"
        href="https://wa.me/5511941617953"
        title="Suporte"
        target="_blank"
      />

      <nav-menu-link
        :icon="icons.mdiFrequentlyAskedQuestions"
        :to="{ name: 'faq' }"
        title="FAQ"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiApi,
  mdiAccountCircleOutline,
  mdiBadgeAccount,
  mdiCancel,
  mdiCashMultiple,
  mdiCheckAll,
  mdiCounter,
  mdiFactory,
  mdiFileDocument,
  mdiFileChart,
  mdiFilterMenuOutline,
  mdiHomeOutline,
  mdiMessageDraw,
  mdiMessageTextOutline,
  mdiPhonePlusOutline,
  mdiPiggyBankOutline,
  mdiWhatsapp,
  mdiStairs,
  mdiCash,
  mdiHeadCog,
  mdiFrequentlyAskedQuestions,
  mdiVideo,
} from '@mdi/js'
import { ROLE_TYPE } from '@/store/auth/mutations'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiApi,
        mdiAccountCircleOutline,
        mdiCancel,
        mdiCashMultiple,
        mdiCheckAll,
        mdiCounter,
        mdiFactory,
        mdiFileDocument,
        mdiFileChart,
        mdiFilterMenuOutline,
        mdiHomeOutline,
        mdiMessageDraw,
        mdiMessageTextOutline,
        mdiPhonePlusOutline,
        mdiPiggyBankOutline,
        mdiWhatsapp,
        mdiStairs,
        mdiCash,
        mdiHeadCog,
        mdiBadgeAccount,
        mdiFrequentlyAskedQuestions,
        mdiVideo,
      },
    }
  },
  computed: {
    isAdmin() {
      const role = Number(Buffer.from(localStorage.getItem('role'), 'base64'))

      return role === ROLE_TYPE.admin
    },

    isAffiliate() {
      return String(localStorage.getItem('isAffiliate')) === 'true'
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }

  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
