import Vue from 'vue'
import axios from '@/plugins/axios'

export const getDetails = async ({ commit }, params) => {
  try {
    const { data } = await axios.get('report/service-details', { params })

    commit('setServiceDetails', data)

    return data
  } catch (err) {
    return err
  }
}

export const sms = async (_, params) => {
  try {
    const { data } = await axios.get('report/service-details/sms', { params })

    return data
  } catch (err) {
    return err
  }
}

export const sanitizer = async (_, params) => {
  try {
    const { data } = await axios.get('report/service-details/sanitize', { params })

    return data
  } catch (err) {
    return err
  }
}

export const voice = async (_, params) => {
  try {
    const { data } = await axios.get('report/service-details/voice', { params })

    return data
  } catch (err) {
    return err
  }
}
